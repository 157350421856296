import { cva } from "class-variance-authority";

export const tagVariants = cva(`inline-flex items-center rounded-full`, {
  variants: {
    variant: {
      default: `bg-muted-foreground/10 text-muted-foreground hover:bg-muted-foreground/30 transition-colors`,
      filled: `bg-primary text-white antialiased`,
      outlined: `text-content/90 ring-1 ring-content/30 bg-background`,
    },
    color: {
      warning: `bg-orange-10 text-orange-70 dark:bg-orange-40 dark:text-black`,
      error: `bg-red-10 text-red-70 dark:bg-red-40 dark:text-black`,
      success: `bg-green-10 text-green-70 dark:bg-green-40 dark:text-black`,
    },
    size: {
      lg: `h-10 px-3 text-base`,
      md: `h-8 px-2.5 text-sm`,
      sm: `h-6 px-2 text-xs`,
      xs: `h-5 px-2 text-xs`,
    },
  },
  defaultVariants: {
    variant: `default`,
    size: "sm",
  },
});

export const tagInner = cva(`flex items-center space-x-2`);
